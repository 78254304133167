<template>
  <SSOConfiguration/>
</template>

<script type="text/babel">
import GroupManager from './GroupManager/GroupManager.vue';
import SSOConfiguration from './SSOManager/SSOConfiguration.vue';

export default {
  name: "SSOConfigurationTabView",
  data() {
    return {
    }
  },
  components: {
    SSOConfiguration,
    GroupManager
  },
  props: ["level"],
  computed: {
    isPropertyLevel() {
      return this.level === 'Property' ? true : false;
    }
  }
};
</script>
