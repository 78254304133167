<template>
    <div>
        <hb-data-table-header>
          <template v-slot:description>
            Add / Manage user groups.
          </template>
          <template v-slot:actions>
            <hb-btn small color="secondary" @click="showConfig()">Add New Group</hb-btn>
          </template>
        </hb-data-table-header>

        <v-card elevation="1">
          <v-data-table
            :headers="headers"
            :items="groups"
            disable-pagination
            hide-default-footer
            class="hb-data-table hb-data-table-cursor-on"
            @click:row="showConfig"
          >
            <template v-slot:item.name="{ item }">{{item.name}}</template>
            <template v-slot:item.description="{ item }">{{ item.description }}</template>
            <template v-slot:item.actions="{ item }">
              <hb-menu
                options
                align-right
              >
                <v-list>
                  <v-list-item @click="showConfig(item)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteItem(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </hb-menu>
            </template>
          </v-data-table>
        </v-card>

        <hb-modal
            :title="title"
            v-model="addGroups"
        >
            <template v-slot:subheader>
                .
            </template>
            <template v-slot:content>
                <hb-form label="Name" required>
                    <HbTextField v-model="group.name"></HbTextField>
                </hb-form>
                <hb-form label="Description" required>
                    <HbTextField v-model="group.description"></HbTextField>
                </hb-form>
                <hb-form label="Properties" required>
                    <hb-combobox
                    v-model="group.selected"
                    :items="propertyItems"
                    id="primary_properties"
                    select
                    item-value="property_id"
                    item-text="name"
                    return-object
                >
                    <template v-slot:item="{item, attrs}">
                        <v-list-item-action> 
                            <v-icon v-if="attrs.inputValue">check_box</v-icon> 
                            <v-icon v-else :color="item.disabled ? '#DFE3E8' : '#9b9b9b'">check_box_outline_blank</v-icon>
                        </v-list-item-action>
                        <v-list-item-content> 
                            <v-list-item-title> 
                                <span v-if="item.number" :class="{'text--disabled': item.disabled}">{{ item.number }} - </span>
                                <span :class="{'text--disabled': item.disabled}">{{ item.city }} - {{ item.name }}</span>
                            </v-list-item-title> 
                        </v-list-item-content> 
                    </template>
                </hb-combobox>
                </hb-form>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="save"><span
              v-if="group.id">Update
              Group</span><span v-else>Save</span></hb-btn>
            </template>
        </hb-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex/dist/vuex.common.js';
import api from '../../../../assets/api';

export default {
    name: 'GroupManager',
    data() {
        return {
            groups: [],
            addGroups : false,
            showEdit: false,
            showDelete: false,
            showRoleAddUpdateAlert : false,
            headers: [
                    { text: "Role Name", value: "name" },
                    { text: "Description", value: "description" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
            addGroups: false,
            title: '',
            group: {
                id: '',
                name: '',
                description: '',
                properties:[],
                selected: []
            }
        }
    },
    created() {
        this.fetchGroups();
    },
    computed: {
            ...mapGetters({
                allPropertiesList: 'propertiesStore/properties',
            }),
            propertyItems() {
                return this.allPropertiesList.map(p => {
                    return {
                        id: p.id,
                        name: p.name,
                        number: p.number,
                        city: p.city,
                        gds_id: p.gds_id,
                        disabled: p.disabled,
                    } 
                });
            },
        },
    methods: {
        async fetchGroups(){
        api.get(this, api.GET_SSOGROUPS).then(r => {
            this.groups = r.groups.filter(item => 
            item.name?.toLowerCase());
        });
        if(this.groups.properties){
            this.groups.properties.forEach(p => {
                                let index = this.allPropertiesList.findIndex(x=> x === p.id)
                                if (index !== -1) {
                                    this.selected.push(p)
                                }
                            });
        }
        },

        editItem(data){
            this.group = data;
            this.addGroups = true;
        },
        showConfig(c = {}){
            this.group.selected = [];
            this.group.id = c.id,
            this.group.name = c.name,
            this.group.description = c.description,
            this.group.properties= c.properties,
                this.addGroups = true;
                if (this.group.id){
                    this.title = 'Edit Groups'
                } else {
                    this.title = 'Add New Group'
                }

                if(this.group.properties){
                    this.allPropertiesList.forEach(p => {
                            let index = this.group.properties.findIndex(x=> x.property_id == p.id)
                                if (index !== -1) {
                                    this.group.selected.push(p)
                                }
                    });
                }
        },
        save(){
                var data = {group: this.group};
                console.log("Data to save : ", data)
                if(this.group.id)
                    api.put(this, api.GET_SSOGROUPS, data)
                else
                    api.post(this, api.GET_SSOGROUPS, data)
                this.addGroups = false;
                this.fetchGroups();

                
        },
        deleteItem(c){
                this.selected = c;
                this.showDelete = true;
        },

    }
}
</script>